.App {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.dataTitle {
	background-color: bisque;
	font-weight: bold;
	padding: 5px;
	margin-top: 10px;
}

.bottom{
	margin-top: 2px;
	margin-bottom: 6px;
}
